import React, {useState} from 'react';
import {Form} from 'react-formio';
//import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} from "react-google-maps"
//import {Map, TileLayer, Marker, Popup} from "react-leaflet"
import ReactMapGL, {Marker, NavigationControl, Popup} from 'react-map-gl';
//import {compose, withProps, withStateHandlers} from "recompose";
import {FaRoad} from "react-icons/fa";
import {FaMapMarkerAlt} from "react-icons/fa/index";
import {Link} from "react-router-dom";

const position = {lat: 47.80278999999999, lng: 16.23318};
//const position=[ 47.80278999999999, 16.23318];
/*
const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <Map center={position} zoom={this.state.zoom}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        <Marker position={position}>
            <Popup>
                A pretty CSS3 popup. <br/> Easily customizable.
            </Popup>
        </Marker>
    </Map>


));*/
{/*
    <GoogleMap
        defaultZoom={8}
        defaultCenter={}
        mapStyles={[
            {
                "featureType": "landscape",
                "stylers": [
                    {"visibility": "on"},
                    {"color": "#f9dd32"},
                    {"lightness": 42}
                ]
            }, {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {"visibility": "on"},
                    {"color": "#ffffff"},
                    {"lightness": 21},
                    {"weight": 2.2}
                ]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {"weight": 2.3},
                    {"color": "#ff0000"},
                    {"lightness": 50}
                ]
            }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {"color": "#008bbc"},
                    {"weight": 3}
                ]
            }, {
                "featureType": "landscape.man_made",
                "stylers": [
                    {"color": "#f9db34"},
                    {"weight": 0.1},
                    {"lightness": 30}
                ]
            }
        ]}
    >
        {props.isMarkerShown && <Marker position={{lat: 47.80278999999999, lng: 16.23318}}/>}
    </GoogleMap>*/
}
/*
$blue: #B6DEFF //rgba(0, 119, 239, 0.22)
$red: #f9d8c5 //rgba(239,93,0,0.22)
$green: rgba(182, 223, 149,1) //B6DF95
 */

/*
const MapWithAMakredInfoWindow = compose(
    withStateHandlers(() => ({
        isOpen: window.innerWidth > 800 ? [1, 2, 3] : [],
    }), {
        onToggleOpen: ({isOpen}) => (tag, open = true) => ({
            isOpen: open ? isOpen.concat([tag]) : isOpen.filter(a => a !== tag),
        })

    }),
    withScriptjs,
    withGoogleMap
)
(props =>
    <GoogleMap
        defaultZoom={window.innerWidth > 1600 ? 9 : (window.innerWidth > 950 ? 8 : (window.innerWidth > 500 ? 7 : 6))}
        defaultCenter={{lat: 48.0, lng: 15}}
        defaultOptions={{
            styles: [
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {"visibility": "on"},
                        {"color": "#ffffff"},
                        {"lightness": 21},
                        {"weight": 2.2}
                    ]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {"weight": 2.3},
                        {"color": "#d68464"},
                        {"lightness": 50}
                    ]
                }, {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {"color": "#BDCDEB"},
                        {"weight": 3}
                    ]
                }, {
                    "featureType": "landscape.man_made",
                    "stylers": [
                        {"color": "#747675"},
                        {"weight": 0.1},
                        {"lightness": 30}
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "stylers": [
                        {
                            "color": "#e0f0c1"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.landcover",
                    "stylers": [
                        {
                            "color": "#e0f0c1"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "stylers": [
                        {
                            "color": "#e0f0c1"
                        }
                    ]
                }
            ]
        }}
    >
        <Marker
            position={{lat: 48.0054395, lng: 16.2409668}}
            onClick={() => props.onToggleOpen(1)}
        >
            {props.isOpen.indexOf(1) > -1 && <InfoWindow onCloseClick={() => props.onToggleOpen(1, false)}>
                <div style={{fontSize: "18px"}}>
                    <b>Klaus Mathis</b><br/>
                    Palffygasse 2/1, 2500 Baden<br/>
                    <a href={"https://www.google.com/maps/dir//Palffygasse+2,+2500+Baden/@48.0054395,16.2409668,17"} target={"_blank"}><FaRoad style={{verticalAlign: "middle"}}/> Route </a>
                </div>
            </InfoWindow>}
        </Marker>
        <Marker
            position={{lat: 48.1685755, lng: 16.344766}}
            onClick={() => props.onToggleOpen(2)}
        >
            {props.isOpen.indexOf(2) > -1 && <InfoWindow onCloseClick={() => props.onToggleOpen(2, false)}>
                <div style={{fontSize: "18px"}}>
                    <b>Klaus Mathis</b> c/o KICK OFF Management Consulting GmbH<br/>
                    Wienerbergerstraße 11, 1100 Wien<br/>
                    <a href={"https://www.google.com/maps/dir//Wienerbergstraße+11,+1100+Wien,+Österreich/@48.1685755,16.344766,17"} target={"_blank"}><FaRoad style={{verticalAlign: "middle"}}/> Route </a>
                </div>
            </InfoWindow>}
        </Marker>

        <Marker
            position={{lat: 47.7961358, lng: 13.0177659}}
            onClick={() => props.onToggleOpen(3)}
        >
            {props.isOpen.indexOf(3) > -1 && <InfoWindow onCloseClick={() => props.onToggleOpen(3, false)}>
                <div style={{fontSize: "18px"}}>
                    <b>Klaus Mathis</b><br/>
                    c/o Dr. T. Fritsch - Zahnheilkunde<br/>
                    Rochusgasse 13<br/>
                    5020 Salzburg-Maxglan<br/>
                    <a href={"https://www.google.com/maps/dir//Rochusgasse+13,+5020+Salzburg,+Österreich/@47.7961358,13.0177659,17"} target={"_blank"}><FaRoad style={{verticalAlign: "middle"}}/> Route </a>
                </div>
            </InfoWindow>}
        </Marker>


    </GoogleMap>
);

*/
function MyPopup({children, ...props}) {
    const [open, setOpen] = useState(window.innerWidth > 800);
    return <div className={"mapMarker"}>
        <Marker {...props} offsetTop={-26} offsetLeft={-13} className={"marker"} onClick={() => setOpen(!open)}>
            <em onClick={() => setOpen(!open)}>
                <FaMapMarkerAlt/>
            </em>
        </Marker>
        {
            open &&
            <Popup {...props} offsetTop={-36} closeButton={false}>
                {children}
            </Popup>
        }
    </div>
}

class Footer extends React.Component {

    state = {
        viewport: {
            width: "100%",
            height: 400,
            latitude: 48.,
            longitude: 15.,
            zoom: window.innerWidth > 1600 ? 8 : (window.innerWidth > 950 ? 7 : (window.innerWidth > 500 ? 6 : 5)),
            mapboxApiAccessToken: "pk.eyJ1IjoibWF0c2NoaW5lciIsImEiOiJjazF4c2F2N2cwZWJwM2VxdGZ1MjRjNmJqIn0.mpW7aGOL45wHRIkiIL2BSQ"
        },
        showPopup: true,
        isOpen: [],// ? [1, 2, 3] : [],
    };
    onToggleOpen = ({isOpen}) => (tag, open = true) => ({
        isOpen: open ? isOpen.concat([tag]) : isOpen.filter(a => a !== tag),
    });

    render() {

        const date = new Date();
        const props = {};
        return <React.Fragment>
            <footer>
                <div key={1}>
                    <h1 style={{textAlign: "center"}}>Ich freue mich über Ihre Kontaktaufnahme</h1>
                    <br/><br/>
                </div>
                <div className={"contact"} id={"contact"} key={2}>
                    <span style={{fontSize: "120%"}}>per Telefon unter <a href="tel:+436764080370">+43 (0) 676 / 4080370</a></span>
                    <br/><br/>
                    <hr/>
                    <br/>
                    <span style={{fontSize: "120%"}}>per Email</span>
                    <Form src="https://sfkieclviyrvzho.form.io/kontakt"/>
                </div>
            </footer>
            {/*<div className={"map"} key={3}>
                <ReactMapGL
                    {...this.state.viewport}
                    onViewportChange={(viewport) => this.setState({viewport})}
                    mapStyle='mapbox://styles/mapbox/streets-v11'
                >
                    <div style={{position: 'absolute', right: 10}}>
                        <NavigationControl/>
                    </div>

                    <MyPopup
                        latitude={48.1685755} longitude={16.344766}
                        anchor="right"
                    >
                        <div style={{fontSize: "18px"}}>
                            <b>Klaus Mathis</b> c/o KICK OFF Management Consulting GmbH<br/>
                            Wienerbergerstraße 11, 1100 Wien<br/>
                            <a href={"https://www.google.com/maps/dir//Wienerbergstraße+11,+1100+Wien,+Österreich/@48.1685755,16.344766,17"} target={"_blank"}><FaRoad style={{verticalAlign: "middle"}}/> Route </a>
                        </div>
                    </MyPopup>
                    <MyPopup
                        latitude={47.7961358} longitude={13.0177659}
                        anchor="bottom">
                        <div style={{fontSize: "18px"}}>
                            <b>Klaus Mathis</b><br/>
                            c/o Dr. T. Fritsch - Zahnheilkunde<br/>
                            Rochusgasse 13<br/>
                            5020 Salzburg-Maxglan<br/>
                            <a href={"https://www.google.com/maps/dir//Rochusgasse+13,+5020+Salzburg,+Österreich/@47.7961358,13.0177659,17"} target={"_blank"}><FaRoad style={{verticalAlign: "middle"}}/> Route </a>
                        </div>
                    </MyPopup>

                </ReactMapGL>

                <MapWithAMakredPopup
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCtggSIE9gnMBlrT0Yg0p0Fm-_o7LTsGrE&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `550px`}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                />
            </div>
            */}


            <footer>
                <div key={4} style={{marginTop: "40px"}}>
                    <hr/>
                    &#x00A9; Klaus Mathis {date.getFullYear()},&nbsp;
                    <Link to={"/de/impressum"}>Impressum</Link>
                </div>
            </footer>
        </React.Fragment>
    }
}

/* <MyMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCtggSIE9gnMBlrT0Yg0p0Fm-_o7LTsGrE&signed_in=true&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `530px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                */
export default Footer;
