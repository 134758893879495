import "babel-polyfill";
import {polyfill} from 'es6-promise';
import 'core-js';
import 'raf/polyfill';
import Intl from "intl";
import {IntlProvider, FormattedNumber, addLocaleData} from "react-intl"
import de from "react-intl/locale-data/de"
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/array';
import 'core-js/es/reflect';
import 'classlist.js';
import regeneratorRuntime from "regenerator-runtime";

import 'web-animations-js';
import "intl/locale-data/jsonp/en.js"

import React from 'react';
import registerServiceWorker from './registerServiceWorker';
import './main.sass';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from "./home"
import Footer from "./footer"
import Porträt from "./klausmathis-header.jpg"
import Logo from "./logo_stripes_spacy.svg"
import Nav from "./nav"
import AsyncComponent from "./AsyncComponent";

//library.add(faEnvelope, faKey);
try {
    addLocaleData(de)
    polyfill();

} catch (e) {
    console.log(e)
}


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return<IntlProvider locale={"de"}>
            <Router onUpdate={() => window.scrollTo(0, 0)}>
                <div className="App">
                    <header className="App-header">
                        {/*<Route path="/:lang" component={LanguageChange}/>*/}
                        <div id={"images"} onClick={()=>window.location.href="/"} style={{cursor: "pointer"}}>
                            <img src={Porträt} height={"200px"} className={"animgrey porträt"}/>
                            <img src={Logo} height={"175px"} className={"logo"}/>
                        </div>
                        <Route path="/:lang" component={Nav}/>
                        <Route exact path="/" component={Nav}/>
                    </header>
                    <article>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/:lang" component={Home}/>
                        <Route exact path="/:lang/Coaching-Systems" component={Home}/>
      {/*                  <Route path="/:lang/Bereiche" component={AsyncComponent(()=>import("./bereiche"))}/>
                        <Route path="/:lang/fields" component={AsyncComponent(()=>import("./bereiche"))}/>*/}
                        <Route exact path="/:lang/Imprint" component={AsyncComponent(()=>import("./contact"))}/>
                        <Route exact path="/:lang/Impressum" component={AsyncComponent(()=>import("./contact"))}/>
                    {/*    <Route path="/:lang/About" component={AsyncComponent(()=>import("./about"))}/>
                        <Route path="/:lang/Über" component={AsyncComponent(()=>import("./about"))}/>
                        <Route exact path="/:lang/test" component={AsyncComponent(()=>import("./blog"))}/>
                        <Route exact path="/:lang/neuigkeiten" component={AsyncComponent(()=>import("./facebook"))}/>
                        <Route exact path="/:lang/blog" component={AsyncComponent(()=>import("./blog"))}/>
                        <Route path="/:lang/blog/:post" component={AsyncComponent(()=>import("./blog"))}/>*/}


                    </article>

                    <Route path="/" component={Footer}/>

                </div>

            </Router>
        </IntlProvider>
    }
}

//
/*
<Route exact path="/" component={Home}/>
                        <Route path="/Bereiche" component={Home}/>
                        <Route path="/Bereiche" component={Bereiche}/>

                        <Route exact path="/" component={Bereiche}/>

 */
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);


registerServiceWorker();
