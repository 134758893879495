import React from 'react';

import {NavLink as Link, Redirect} from "react-router-dom"


class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.ll = this.ll.bind(this)
    }

    ll(object) {
        return object[this.props.match.params.lang]
    }

    render() {
        return null;
        const lang = this.props.match.params.lang;
        if (lang === undefined || lang === "") {
            return <Redirect to="/de"/>
        }
        if (["de", "en"].indexOf(lang) === -1) {
            return <Redirect to={"/de" + this.props.location.pathname + "/"}/>
        }
        return (
            <nav>
                <Link to={"/" + lang + "/Coaching-Systems"} activeClassName="active"><span/>{this.ll({de: "Coaching Systems", en: "Coaching Systems"})}<span/></Link>
                {/*<Link to={"/" + lang + "/" + this.ll({de: "blog", en: "blog"})} activeClassName="active"><span/>{this.ll({de: "Neuigkeiten", en: "News"})}<span/></Link>*/}
                {/*<Link to={"/" + lang + "/" + this.ll({de: "Über", en: "About"})} activeClassName="active"><span/>{this.ll({de: "Über mich", en: "About Me"})}<span/></Link>*/}
                <Link to={"/" + lang + "/" + this.ll({de: "Impressum", en: "Imprint"})} activeClassName="active"><span/>{this.ll({de: "Standorte", en: "Locations"})}<span/></Link>
            </nav>
        )
    }
}

export default Nav;
