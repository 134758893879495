import React from 'react';
import './home.sass'
import WordpressPage from "./storyblok";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.ll = this.ll.bind(this)
    }

    ll(object) {
        return object[this.props.match.params.lang]
    }

    render() {
        const lang = this.props.match.params.lang;
        return (
            <div>
                <div id={"name_and_title"}><h1>Klaus Mathis</h1> Lebens und Sozialberater &nbsp;
                </div>
                <WordpressPage slug={"coaching-systems"}/>
                {/*<h1>Coaching Systems</h1>
                <p>... ist der Sammelbegriff für meine vielfältigen Angebote an Coaching-und
                    Beratungsthemen. Meine Aufgabe sehe ich darin, Kunden bei der Erreichung ihrer Ziele und Bedürfnisse
                    unterstützend zu begleiten und ihnen damit den entsprechenden Auftritt auf der Bühne des Lebens zu
                    ermöglichen.</p>

                <p> Die verschütteten Gefühle, sowie Traumata, führen aus dem Unterbewusstsein heraus zu einer nicht
                    wahrgenommenen wesentlichen Beeinflussung des Denk-Entscheidungsprozesses.
                    Diese emotionalen Hürden, die jede Ebene des Lebens beeinflussen können, versuche ich in
                    Einzelsettings zu heben, zu integrieren und damit dem unbewussten Einfluss zu entziehen.</p>

                <p>Ängste sind unsere größten Feinde. Sie aussprechen zu können, ist der erste Schritt diese abzubauen.
                    „Das habe ich noch niemanden erzählt!“ oder „Über das zu sprechen, war mir erst mit Ihnen möglich!“,
                    höre ich oft bei den Sitzungen. Ihre Bereitschaft zur Selbstreflexion vorausgesetzt, werden wir
                    diese im gemeinsamen Gespräch zu lösen versuchen.</p>

                <h3>Angebote an Coaching und Beratungsthemen</h3>
                <Link to={"/" + lang + "/" + this.ll({de: "Bereiche", en: "fields"}) + "/Lingosomatis"}
                      activeClassName="active">Lingosomatis ®</Link>
                <ul style={{paddingLeft: "33px"}}>
                    <li>Angstabbau</li>
                    <li>Paarberatung im Unternehmensbereich</li>
                    <li>Partnerschaftliche Probleme, Sexualität</li>
                    <li>Persönlichkeitsentwicklung und Vertrauensbildung</li>
                    <li>Schmerzlinderung bei Verspannungen, Migräne…</li>
                    <li>Trauerarbeit</li>
                    <li>OP-Nachbetreuung</li>
                    <li>Co-Therapeut bei systemischen Familienstellen <a target={"_blank"} href="https://www.kick-off.at/aktuelles/termine/?tx_events_events%5Bevents%5D=54&tx_events_events%5Baction%5D=show&tx_events_events%5Bcontroller%5D=Events&cHash=daca29568559b2c4b7928296c97aa300">
                        www.kick-off.at/Familien-und-Organisationsaufstellungs-Tag</a>.
                        <br/>
                        <Link to={"/" + lang + "/" + this.ll({de: "Bereiche", en: "fields"}) + "/Lingosomatis"}>mehr
                            erfahren <FaArrowAltCircleRight style={{verticalAlign: "middle"}}/></Link></li>
                </ul>

                Störungen des Essverhaltens
                <ul  style={{paddingLeft: "33px"}}>
                    <li>
                        <a href={"https://entzuckern.at/methode/"} target={"_blank"}>mehr erfahren auf
                            entzuckern.at <FaArrowAltCircleRight style={{verticalAlign: "middle"}}/></a>
                    </li>
                </ul>*/}


                {/*<nav>
                    <div className={"ad_bereich"} id={"ad_diat"}>
                        <Link
                            to={"/" + lang + "/" + this.ll({de: "Bereiche/Ketogene-Diät", en: "fields/ketogenic-diet"})}
                            id={"ketogene-diät"} activeClassName="active">{this.ll({
                            de: "Ketogene Diät",
                            en: "Ketogenic Diet"
                        })}</Link>

                    </div>
                    <div className={"ad_bereich"}>



                    </div>


                    {/*<Link
                        to={"/" + lang + "/" + this.ll({de: "Bereiche/Körpertherapie", en: "fields/body-therapy"})}
                        id={"körpertherapie"} activeClassName="active">{this.ll({
                        de: "Körpertherapie",
                        en: "Body Therapy"
                    })}</Link>


                </nav>
*/
                }

                    </div>
                    )
                    }
                }

                export default Home;
