import React from "react"
import axios from "axios"

export default class WordpressPage extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        const {slug} = props;
        axios.get("https://klausmathis.linkom.at/wp-json/wp/v2/pages?_fields=content,title&slug=/" + (slug || "")).then(({data}) => {
            this.setState({content: data})
        })
    }

    render() {
        const {content} = this.state;
        return (content || []).map(c => <>
                <h1>{c.title.rendered}</h1>
                <div dangerouslySetInnerHTML={{__html: c.content.rendered}} className="blog__body"/>
            </>
        )
    }
}
